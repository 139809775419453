<template>
    <div class="item__image" :class="bloc.class">
        <img :src="getImgUrl(bloc.image)" :alt="bloc.image" :id="bloc.id" :style="'max-width: '+bloc.max+'px ;'"/>
    </div>
</template>

<script>
export default {
  props: ['bloc'],
    mounted() {
        this.responsiveValue();
        window.addEventListener("resize", this.responsiveValue);
    },
    unmounted() {
        window.removeEventListener("resize", this.responsiveValue);
    },
  methods: {
      getImgUrl(img) {
          return require('../assets/img/'+img)
      },
      responsiveValue() {
          let x = 0;
          let y = 0;

          if(this.$props.bloc.x) {

              x = this.$props.bloc.x;
          }
          if(this.$props.bloc.y) {
              y = this.$props.bloc.y;
          }

          if(this.$props.bloc.responsive && this.$props.bloc.responsive.x && window.innerWidth > 768) {
              x = this.$props.bloc.responsive.x;
          }
          if(this.$props.bloc.responsive && this.$props.bloc.responsive.y && window.innerWidth > 768) {
              y = this.$props.bloc.responsive.y;
          }

          this.$el.style.marginLeft = x;
          this.$el.style.marginTop = y;
          //this.$el.style.transform = "translateY("+y+")";

          let width = this.$props.bloc.width;
          if(this.$props.bloc.responsive && this.$props.bloc.responsive.width && window.innerWidth > 768) {
              width = this.$props.bloc.responsive.width;
          }
          if(width) {
              this.$el.querySelector('img').style.width = width+"px";
          }

          let height = this.$props.bloc.height;
          if(this.$props.bloc.responsive && this.$props.bloc.responsive.height && window.innerWidth > 768) {
              height = this.$props.bloc.responsive.height;
          }
          if(height) {
              this.$el.querySelector('img').style.height = height+"px";
          }

          let rotate = this.$props.bloc.rotate;
          if(this.$props.bloc.responsive && this.$props.bloc.responsive.rotate && window.innerWidth > 768) {
              rotate = this.$props.bloc.responsive.rotate;
          }
          if(rotate) {
              this.$el.style.transform = "rotate("+rotate+"deg)"
          }

      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.item__image {
  padding: 10px 0;

  img {
    max-width: 100%;
  }
}

.container__colon {
  .item__image {
    padding: 20px 0;

    img {
      max-width: none;
      transition: all ease .3s;
    }
  }
}

.custom__modal {
  img {
    max-width: 100%!important;
  }
}

  #img_intro {
    @media (min-width: 768px) {
     margin-top: 60px;
    }
  }
</style>
