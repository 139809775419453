<template>
  <div :data-zone="zone" :style="'font-style: '+bloc.style + '; font-size: '+bloc.size + 'px; font-weight: '+bloc.bold + ''" v-html="bloc.text">
  </div>
</template>

<script>
export default {
  props: ['bloc', 'zone'],
    mounted() {
        this.responsiveClass();
    },
    methods: {
        responsiveClass() {
            let classes = ['item__texte'];
            let bloc = this.$props.bloc;

            if(bloc.size) {
                classes.push('item__texte--' + bloc.size);
            }
            if(bloc.position) {
                classes.push('item__texte--' + bloc.position);
            }
            if(bloc.color) {
                classes.push('item__texte--' + bloc.color);
            }

            if(bloc.responsive && bloc.responsive.size) {
                classes.push('item__texte--desk--' + bloc.responsive.size);
            }
            if(bloc.responsive && bloc.responsive.position) {
                classes.push('item__texte--desk--' + bloc.responsive.position);
            }
            if(bloc.responsive && bloc.responsive.color) {
                classes.push('item__texte--desk--' + bloc.responsive.color);
            }
            classes = classes.join(' ');
            this.$el.setAttribute('class', classes);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.item__texte {
  padding: 10px 0;
  text-align: left;
  line-height: $lh-normal;

  &--little {
      font-size: $fs-little;
      line-height: $lh-little;
      text-align: center;
   }
  &--big {
    font-size: $fs-big;
    line-height: $lh-big;
  }
  &--normal {
    font-size: $fs-normal;
    line-height: $lh-normal;
  }
  &--overlay {
    text-transform: uppercase;
    font-weight: bold;
    font-size: $fs-20;
    line-height: $fs-20;
    margin-right: auto;
    background-color: $c-blue-dark;
    width: fit-content;
    padding: 2px 10px 2px 5px;
    text-align: left;
    margin-bottom: 5px;
  }

  &--right {
    width: 85%;
    margin-left: auto;
  }

  &--center {
    text-align : center;
  }

  &--blue {
    color: $c-blue!important;
  }
  &--blue-dark {
    color: $c-blue-dark!important;
  }

  b {
    font-weight: 600!important;
  }

  @media (min-width: 768px) {
    &--desk {
      &--right {
        width: 85%;
        margin-left: auto;
      }

      &--center {
        text-align : center;

        &.item__texte--right {
          width: 100%;
        }
      }

      &--left {
        text-align : left;
      }
    }
  }
}

.container__colon {
  .item__texte {
    color: $c-white;
  }
}

.custom__modal {
  .item__texte {
    color: $c-blue;
  }
}

</style>
