<template>
    <div v-if="checkType(bloc.type, 'capsules')" class="capsule__container py-4 d-flex justify-content-center flex-wrap">
        <div v-for="capsule in bloc.capsules" :key="capsule.type" class="capsule__item my-1">
            <div class="capsule__inner py-4 py-md-5 px-2">
                <svg xmlns="http://www.w3.org/2000/svg" :style="'fill: '+capsule.color +''" viewBox="0 0 151.97 426.02"><g id="Calque_2" data-name="Calque 2"><g id="Calque_2-2" data-name="Calque 2"><path d="M4.7,22.17S3.81,0,14.45,0c0,0,11.53-.88,8,22.17,0,0-7.32,41.24,21.28,67,0,0,16.4,15.07,20.84,16.84,0,0-.17,2.09-6.21,8.43,0,0-5.76,6.65-11.53,2.22C46.82,116.62,4.46,84,4.7,22.17Z"/><path d="M92.05,134.35s9.09-10.42,19.51,0c0,0,50.54,40.35,38.57,94,0,0-4.88,37.69-44.78,67.84,0,0-7.76,7.09-13.08-.89,0,0-5.54-8.87-.22-12,0,0,37.24-22.17,42.12-56.31,0,0,11.08-55.42-38.13-82.91C96,144.11,85.4,139.45,92.05,134.35Z"/><path d="M60.57,314.14s9.31,7.54-2,12.64c0,0-30.59,16-35.91,49,0,0-4,24.16,0,38.57,0,0,3.54,9.53-6.21,11.53,0,0-7.54,1.11-10-8,0,0-4.43-26.16,0-42.79,0,0,5.77-29.26,22.39-45.89,0,0,11.53-12,17.3-15.08C46.16,314.14,53.69,307.94,60.57,314.14Z"/><path d="M130.84,414.79s-2.21,11.13,7.54,11.13c0,0,9.09,1.95,9.09-11.13,0,0,7.32-66.5-26.62-89.78,0,0-65.16-41.46-78.91-55,0,0-44.56-37.47-17.07-88,0,0,21.1-35,57-52.3,0,0,55.4-22.42,62.72-65.42,0,0,4.88-37.47,1.55-55.21a9.46,9.46,0,0,0-8.2-7.53s-6.87-.67-7.32,7.09c0,0,2.44,40.22-3.1,50.42,0,0-12,35.82-51.43,54.66,0,0-54.1,27.27-66.73,61.19,0,0-31.93,63.85,22.17,107.08l67,46.56s22.37,13.3,28.6,35.69C127.08,364.25,133.73,394,130.84,414.79Z"/><rect x="36.77" y="27.83" width="79.81" height="11.53" rx="5.76"/><rect x="41.81" y="182.35" width="69.8" height="11.53" rx="5.76"/><rect x="41.81" y="234.25" width="69.8" height="11.53" rx="5.76"/><rect x="41.81" y="387.46" width="69.8" height="11.53" rx="5.76"/><rect x="47.63" y="53.31" width="57.45" height="11.53" rx="5.76"/><rect x="47.63" y="361.89" width="57.45" height="11.53" rx="5.76"/><rect x="29.62" y="207.39" width="94.84" height="11.53" rx="5.76"/></g></g></svg>

                <div :style="'color: '+capsule.color +''" v-html="capsule.titre" class="mt-3"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue'

    export default defineComponent({
        props: ['bloc'],
        methods: {
            checkType(type, check) {
                if(check === type) {
                    return true;
                }
                return false;
            }
        }
    })
</script>

<style lang="scss">
    .capsules {
        .capsule__container {

            .capsule__item {
                padding: 0 2px;
                width: calc(100% / 5);

                @media (max-width: 767px) {
                    width: 50%;
                }

                .capsule__inner {
                    width: 100%;
                    height: 100%;
                    background-color: #364178;
                    border: 1px solid #e1e1e1;
                    border-radius: 10px;

                    svg {
                        width: 40px;
                    }

                    div {
                        font-style: italic;
                        font-weight: 600;
                        font-size: 15px;
                    }
                }
            }
        }

    }

</style>
