<template>

  <div class="bloc__liens pt-2 pb-3">
    <div class="bloc__liens--title text-start fw-bold mb-2 d-flex align-items-center px-3">
      <img src="../assets/img/externe.png" width="12" alt="externe" class="me-2"/>
      {{ bloc.title }}
    </div>
    <ul class="item__liens">
      <li v-for="link in bloc.links" :key="link.type" class="item__liens--items text-start pb-3 mb-2 px-3">
        <a :href="link.link" class="text-start">{{link.text}}</a>
      </li>
    </ul>
  </div>

</template>

<script>

export default {
  props: ['bloc'],
  methods : {
      checkType(type, check) {
          if(check === type) {
              return true;
          }
          return false;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .bloc__liens {
    background-color: white;
    border-bottom: 5px $c-blue-dark solid;
    margin: 40px 0;

    &--title {
      color: $c-blue-dark;
      text-transform: uppercase;
      font-size: $fs-little;
    }

    .item__liens {
      list-style: none;
      padding: 0;
      margin: 0;


      &--items {
        font-size: $fs-middle;
        line-height: 17px;
        border-bottom: 1px solid $c-grey-border;

        &:last-child {
          padding-bottom: 0!important;
          border: none!important;
        }

        a {
          color: $c-blue-dark;
        }
      }
    }
  }

</style>
