<template>
  <div class="d-flex spacer" :class="[bloc.direction, bloc.extraClass]">
    <div v-if="checkType(bloc.direction)" class="spacer--separator"></div>
  </div>
</template>

<script>

export default {
  props: ['bloc', 'cpt'],
    mounted() {
        this.responsiveSize();
        window.addEventListener("resize", this.responsiveSize);
    },
    unmounted() {
        window.removeEventListener("resize", this.responsiveSize);
    },
  methods : {
      checkType(type) {
          if(type === "vertical" || type === "horizontal" || type === "horizontal-full") {
              return true;
          }
          return false;
      },
      responsiveSize() {
          let finalSize = 0;
          let bloc = this.$props.bloc;

          if(bloc.size) {
              finalSize = bloc.size;
          }

          if(bloc.responsive && bloc.responsive.size && window.innerWidth > 768) {
              finalSize = bloc.responsive.size;
          }

          if(this.$el) {
              this.$el.style.height = finalSize+"px";
          }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  .spacer {
    &.vertical {
      margin: 25px 0;
      justify-content: center;
      width: 100%;
      height: auto!important;

      .spacer--separator {
        width: 1px;
        height: 65px;
        background-color: $c-blue;
      }
    }

    &.horizontal {
      justify-content: center;
      width: 100%;
      height: auto!important;

      .spacer--separator {
        height: 1px;
        width: 65px;
        background-color: $c-blue;
      }

      &-full {
        margin: 30px 0;
        justify-content: center;
        width: 100%;
        height: auto!important;

        .spacer--separator {
          height: 0.5px;
          width: 100%;
          background-color: $c-blue;
        }
      }
    }
  }
</style>
