<template>
  <a :href="setLink()" target="_blank" :data-target="bloc.link" v-html="bloc.text" :class="['item__btn--' + bloc.color]" class="item__btn text-uppercase ff-anton mx-auto"></a>

  <ModalComponents :bloc="bloc.modal" :id="formatId(bloc.link)" v-if="bloc.modal"/>
</template>

<script>
export default {
  props: ['bloc'],
    methods : {
      formatId(id) {
          return id.replace('#', '');
      },
      setLink() {
         // "external" : true
        if(this.$props.bloc.external != null && this.$props.bloc.link) {
            return this.$props.bloc.link;
        }
        return "#modal";
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  .item__btn {
    border: none;
    border-radius: 25px;
    padding: 7px 20px;
    font-size: $fs-20;
    text-decoration: none;
    display: inline-block;
    width: 100%;
    max-width: 375px;
    min-width: 300px;
    background-color: $c-blue;
    color: white;
    margin: 24px auto;
    z-index: 999999999;
    position: relative;

    @media (min-width: 768px) {
      max-width: 300px;
    }

    &:hover {
      color: white;
    }

    &--blue {
      background-color: $c-blue;
      margin: 24px auto;
      color: white;
    }

    &--white {
      color: $c-blue;
      background-color: white;
      margin: 12px auto;

      &:hover {
        color: $c-blue;
      }
    }
  }

</style>
