<template>
  <PageComponents :page="page" v-if="page"/>
  <CookieComponents lang="en-SG"/>
</template>

<script>
// @ is an alias to /src
import dataEN from '@/assets/json/en.json'
import PageComponents from '../components/PageComponents'
import CookieComponents from '../components/CookieComponents'

import global from '../assets/js/app.js';

export default {
  name: 'ENView',
  components: {
      PageComponents,
      CookieComponents
  },
  methods: {
      checkType(type, check) {
          if(check === type) {
              return true;
          }
          return false;
      },
      getImgUrl(img) {
          return require('../assets/img/'+img)
      }
  },
  mounted() {
      global.init();
  },
  data() {
      return {
          page: dataEN,
      };
  }
}
</script>