<template>
    <div class="custom__modal" :id="id">
        <div class="custom__modal--inner py-5">
            <div class="custom__modal--container">
                <div class="custom__modal--close d-flex align-items-center mb-5">
                    <img src="../assets/img/arrow-left.png" alt="go back" width="28" class="me-2"/>
                    <a href="" class="c-blue f-little" @click.prevent="">{{bloc.back}}</a>
                </div>
            </div>

            <div class="container--custom">
                    <div v-if="checkType(bloc.type, 'menu')" class="">
                        <h1 v-html="bloc.title" class="item__titre c-blue-dark f-titre-h1 text-uppercase ff-anton normal text-start mb-0"></h1>
                        <div class="item__texte f-20 c-blue mb-4" v-html="bloc.text"></div>

                        <div v-for="(link, index) in bloc.links" :key="index" class="custom__modal--menu-item d-flex py-3">
                            <a :href="link.link" class="c-blue ff-anton text-start">{{link.text}}</a>
                        </div>
                    </div>

                    <div v-if="checkType(bloc.type, 'lightbox')" class="custom__modal--lightbox">
                        <h2 v-html="bloc.intro.chapter" class="item__titre c-blue-dark ff-anton my-3 text-center f-normal text-center text-uppercase"></h2>

                        <div class="d-flex spacer vertical">
                            <div class="spacer--separator"></div>
                        </div>

                        <h1 v-html="bloc.intro.title" class="item__titre c-blue-dark f-titre-h1 text-uppercase ff-anton text-center mb-4 big"></h1>

                        <div v-html="bloc.intro.subtitle" class="item__texte--overlay py-2 mb-5"></div>


                        <div v-for="(b, index) in bloc.blocs" :key="index">
                            <ImageComponents :bloc="b" v-if="checkType(b.type, 'image')"/>

                            <TexteComponents :bloc="b" v-if="checkType(b.type, 'text')"/>

                            <TitreComponents :bloc="b" v-if="checkType(b.type, 'title_h1')"/>
                            <TitreComponents :bloc="b" v-if="checkType(b.type, 'title_h2')"/>
                            <TitreComponents :bloc="b" v-if="checkType(b.type, 'title_full')"/>

                            <ButtonComponents :bloc="b" v-if="checkType(b.type, 'button')"/>
                            <SpacerComponents :bloc="b" v-if="checkType(b.type, 'spacer')"/>

                            <ListeComponents :bloc="b" v-if="checkType(b.type, 'list')"/>

                            <ChiffreComponents :bloc="b" v-if="checkType(b.type, 'figure')"/>

                            <CarouselComponents :bloc="b" v-if="checkType(b.type, 'slider_pdf')"/>
                            <VideoComponents :bloc="b" v-if="checkType(b.type, 'video')"/>

                            <LiensComponents :bloc="b" v-if="checkType(b.type, 'further_information')"/>

                            <div v-if="checkType(b.type, 'double_banner')" class="py-5 my-4">
                                <div class="banner--item c-white f-normal ff-anton text-center px-4 py-3 d-flex align-items-center justify-content-center">
                                    <span class="banner--item--separator"></span> {{ b.text }} <span class="banner--item--separator"></span> {{ b.text }} <span class="banner--item--separator"></span> {{ b.text }} <span class="banner--item--separator"></span>
                                    {{ b.text }} <span class="banner--item--separator"></span> {{ b.text }} <span class="banner--item--separator"></span> {{ b.text }} <span class="banner--item--separator"></span>
                                </div>

                                <div class="banner--item--double c-white f-normal ff-anton text-center px-4 py-3 d-flex align-items-center justify-content-center">
                                    <span class="banner--item--separator"></span> {{ b.text }} <span class="banner--item--separator"></span> {{ b.text }} <span class="banner--item--separator"></span> {{ b.text }} <span class="banner--item--separator"></span>
                                    {{ b.text }} <span class="banner--item--separator"></span> {{ b.text }} <span class="banner--item--separator"></span> {{ b.text }} <span class="banner--item--separator"></span>
                                </div>
                            </div>


                        </div>

                        <img src="../assets/img/pierre-fabre.png" width="135" alt="pierre fabre" class="my-2"/>
                        <TexteComponents :bloc='returnAdress()'/>

                    </div>
        </div>

            <div class="custom__modal--container" v-if="id != 'quick-access-menu'">
                <div class="custom__modal--close d-flex align-items-center mt-5">
                    <img src="../assets/img/arrow-left.png" alt="go back" width="28" class="me-2"/>
                    <a href="" class="c-blue f-little" @click.prevent="">{{bloc.back}}</a>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: ['bloc', 'id'],
    methods: {
        checkType(type, check) {
            if(check === type) {
                return true;
            }
            return false;
        },
        returnAdress() {
          return {
            "type" : "text",
            "size" : "little",
            "position" : "center",
            "color" : "black",
            "text" : "Pierre Fabre Singapore Pte.<br> Ltd. 96 Robinson Road,<br> #14-03 SIF Building.<br> Singapore 068899.<br> SG_BMC_2403_02"
          }
        }
    }
}
</script>

<style lang="scss">
    .custom__modal {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        //background-color: rgba(128, 128, 128, 0.3);
        opacity: 0;
        pointer-events: none;
        z-index: -99999999;

        &--container {
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
            padding: 0 45px;
        }

        &--inner {
            width: 100%;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            overflow-x: hidden;
            overflow-y: scroll;
            overflow: hidden scroll;
            transition-delay: 1s;
            transition: transform ease .35s;
            transform: translateX(100%);
            color: $c-blue;
            background-image: url("../assets/img/fond.png");

            &.active {
                transform: none;
            }
        }

        &.active {
            z-index: 99999999999999;
            opacity: 1;
            pointer-events: auto;
        }

        &--menu-item {
            a {
                font-size: $fs-big;
                line-height: $lh-titre-h2;
            }
        }

        .item__texte--overlay {
            color: white;
            background: transparent;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 150px;
            margin-left: auto;

            @media (min-width: 768px) {
                text-align: center;
                margin-right: auto;
                justify-content: center;
                align-items: center;
            }

            span {
                background-color: $c-blue-dark;
                padding: 2px 13px 2px 7px;
                margin-bottom: 5px;

                &:last-child {
                    position: relative;
                    transform: translate(0,0);

                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: -100%;
                        top: 0;
                        bottom: 0;
                        background-color: $c-blue-dark;
                        z-index: -1;

                        @media (min-width: 768px) {
                            display: none;
                        }

                    }
                }
            }
        }

        .banner--item {
            font-size: $fs-titre-chiffre-little;
            line-height: $fs-titre-chiffre-little;

            &--double {
                background-color: $c-blue;
                transform: rotate(8deg);
                position: relative;
                font-size: $fs-titre-chiffre-little;
                line-height: $fs-titre-chiffre-little;
                margin-top: -90px;

                &:after {
                    content: "";
                    position: absolute;
                    left: -50%;
                    top: 0;
                    right: -50%;
                    bottom: 0;
                    background-color: $c-blue;
                    z-index: -1;
                }
            }

            &--separator {
                display: block;
                min-width: 20px;
                min-height: 20px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: white;
                margin: 0 10px;
            }
        }

        .item__image {
            img {
                transform: none!important;
            }
        }
    }

</style>
