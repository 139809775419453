<template>

    <div class="container--image--polyps position-relative">
        <div class="container--custom">
            <ImageComponents :bloc="bloc.image"/>
        </div>

        <ImageComponents :bloc="bloc.polyps"/>
    </div>
</template>

<script>
export default {
  props: ['bloc'],
    mounted() {
        this.responsiveValue();
        window.addEventListener("resize", this.responsiveValue);
    },
    unmounted() {
        window.removeEventListener("resize", this.responsiveValue);
    },
  methods: {
      responsiveValue() {
          let x = 0;
          let y = 0;

          if(this.$props.bloc.x) {

              x = this.$props.bloc.x;
          }
          if(this.$props.bloc.y) {
              y = this.$props.bloc.y;
          }

          if(this.$props.bloc.responsive && this.$props.bloc.responsive.x && window.innerWidth > 768) {
              x = this.$props.bloc.responsive.x;
          }
          if(this.$props.bloc.responsive && this.$props.bloc.responsive.y && window.innerWidth > 768) {
              y = this.$props.bloc.responsive.y;
          }

          this.$el.style.marginLeft = x;
          this.$el.style.marginTop = y;
          //this.$el.style.transform = "translateY("+y+")";

          let width = this.$props.bloc.width;
          if(this.$props.bloc.responsive && this.$props.bloc.responsive.width && window.innerWidth > 768) {
              width = this.$props.bloc.responsive.width;
          }
          if(width) {
              this.$el.querySelector('img').style.width = width+"px";
          }

          let height = this.$props.bloc.height;
          if(this.$props.bloc.responsive && this.$props.bloc.responsive.height && window.innerWidth > 768) {
              height = this.$props.bloc.responsive.height;
          }
          if(height) {
              this.$el.querySelector('img').style.height = height+"px";
          }

          let rotate = this.$props.bloc.rotate;
          if(this.$props.bloc.responsive && this.$props.bloc.responsive.rotate && window.innerWidth > 768) {
              rotate = this.$props.bloc.responsive.rotate;
          }
          if(rotate) {
              this.$el.style.transform = "rotate("+rotate+"deg)"
          }

      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.container--image--polyps {
    .polyps-cut {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%)!important;
        
        @media (max-width: 1200px) {
            img {
                width: 200px!important;
            }
        }

        @media (max-width: 991px) {
            img {
                width: 150px!important;
            }
        }

        @media (max-width: 767px) {
            img {
                width: 120px!important;
            }
        }

        @media (max-width: 575px) {
            img {
                transform: translateX(-38%);
            }
        }
    }

    .pecheur {
        @media (max-width: 1200px) {
            img {
                width: 380px!important;
            }
        }

        @media (max-width: 767px) {
            img {
                width: 280px!important;
            }
        }

        @media (max-width: 575px) {
            img {
                width: 220px!important;
                transform: translateX(10%);
            }
        }
    }
}
</style>
